import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SideMenu from './../components/SideMenu';
import MobileMenu from './../components/MobileMenu';
import Grid from '@mui/material/Grid';
import Copyright from './../components/Copyright';
import Contraception from './../articles/Contraception';
import ChargeMentale from './../articles/ChargeMentale';
import MasculiniteToxique from './../articles/MasculiniteToxique';
import Patriarcat from './../articles/Patriarcat';
import CultureDuViol from './../articles/CultureDuViol';
import { useParams } from "react-router";
import { articlesInfos } from './../articles/ArticlesConsts';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { ThemeProvider } from '@mui/material/styles';
import theme from './../theme';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import ResponsiveAppBar from './../components/ResponsiveAppBar';
import Box from '@mui/material/Box';

const GetInformed = () => {

  const infoArticles = ['La contraception masculine', "La charge mentale", 'La masculinité toxique', "Le patriarcat", "La culture du viol"];

  document.title = 'Hommes féministes | S\'informer';

  let { id } = useParams();

  let articleIndex = articlesInfos.findIndex(x => x.key === id);

  let sources = articlesInfos[articleIndex].sources;
  let addedInfos = articlesInfos[articleIndex].addedInfos;

    return (
        <div>


        <ThemeProvider theme={theme}>
        <ResponsiveAppBar opacity={0.95}/>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container sx={{width:0.95}}>
          <Grid sx={{display:{xs:'none',md:'flex'}}} item md={2}>
            <SideMenu pages={ infoArticles } pagenumber={articleIndex}/>
          </Grid>
          <Grid sx={{alignItems:'center',display:{xs:'flex',md:'none'}}} item xs={12}>
            <MobileMenu/>
          </Grid>
          <Grid item md={7} xs={12}>
            <Paper sx={{textAlign:"justify",minHeight:400,mx:{xs:0,md:5},my:{xs:0,md:5},px:5,backgroundColor:'#FFFFFF',border:1,borderColor:'secondary',borderRadius:5}}>
                {id==="contraception" &&
                <Contraception/>}
                {id==="chargementale" &&
                <ChargeMentale/>}
                {id==="masculinite" &&
                <MasculiniteToxique/>}
                {id==="patriarcat" &&
                <Patriarcat/>}
                {id==="cultureduviol" &&
                <CultureDuViol/>}
              </Paper>
              </Grid>



              <Grid item md={3} xs={12}>
                <Paper sx={{mt:5,backgroundColor:'#FFFFFF',border:1,borderColor:'primary.main',borderRadius:5}}>





                    <Typography variant="h6" component="h4" gutterBottom sx={{fontWeight:'bold',mt:2}}>
                      Pour aller plus loin
                    </Typography>

                    <List>
                    {addedInfos.map((item) => (
                      <ListItem sx={{"&:hover": {backgroundColor: "rgba(0, 0, 0, 0.1)"}}}>
                      <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "secondary.main" }}>
                      {item.type==="link" &&
                        <InsertLinkIcon/>}
                      {item.type==="book" &&
                        <MenuBookIcon/>}
                      {item.type==="insta" &&
                        <InstagramIcon/>}
                      {item.type==="podcast" &&
                        <PodcastsIcon/>}
                        {item.type==="video" &&
                          <SmartDisplayIcon/>}
                      </Avatar>
                    </ListItemAvatar>
                        <Link href={item.link}>{item.title}</Link>
                      </ListItem>
                    ))}
                    </List>

                  </Paper>
                <Paper sx={{mt:5,backgroundColor:'#FFFFFF',border:1,borderColor:'primary.main',borderRadius:5}}>

                    <Typography variant="h6" component="h4" gutterBottom sx={{fontWeight:'bold',mt:2}}>
                      Sources
                    </Typography>

                    <List >
                    {sources.map((item) => (
                      <ListItem sx={{"&:hover": {backgroundColor: "rgba(0, 0, 0, 0.1)"}}}>
                      <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <InsertLinkIcon/>
                      </Avatar>
                    </ListItemAvatar>
                        <Link href={item.link}>{item.title}</Link>
                      </ListItem>
                    ))}
                    </List>
                  </Paper>
          </Grid>
          </Grid>
          </Box>
              <Copyright/>
              </ThemeProvider>
        </div>
    )
}

export default GetInformed
