import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import Button from '@mui/material/Button';
import validateToken from './../requests/ValidateToken';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fr } from "date-fns/locale";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function AddingSingleEntry(props) {
  const [status, setStatus] = React.useState(0);
  const [startingDate, setStartingDate] = React.useState(null);
  const [endingDate, setEndingDate] = React.useState(null);

  const [periodStartDate, setPeriodStartDate] = React.useState(null);
  const [periodEndDate, setPeriodEndDate] = React.useState(null);
  const [periodDuration, setPeriodDuration] = React.useState(0);

  const [analysisDate, setAnalysisDate] = React.useState(null);

  const [numberTotal, setNumberTotal] = React.useState(0);
  const [volume, setVolume] = React.useState(0);

  const { updatePage } = props;


  React.useEffect(() => {

      const checkToken = async () => { 
        await validateToken({status, setStatus});
      }

      checkToken()
      // make sure to catch any error
      .catch(console.error);

    // eslint-disable-next-line
   }, []);

   async function addNewEntry(startingDate, endingDate) {

     console.log(startingDate);

     const start = startingDate.toLocaleDateString('fr-FR').split('/').reverse().join('-') + " " + startingDate.toLocaleTimeString();
     const end = endingDate.toLocaleDateString('fr-FR').split('/').reverse().join('-') + " " + endingDate.toLocaleTimeString()

     await axios.put("https://api.hommes-engages.fr/api/wearings", {startingDate: start, endingDate: end, status: 0}, {withCredentials:true, CacheControl: "Public"})
     .then(function (response) {
       if (response.status===201) {
         console.log(response);
         updatePage();
       }
       else {
         console.log(response);
       }
     })
     .catch(function (error) {
       console.log(error);
     });

   }

   async function addNewPeriod(periodStartDate, periodEndDate, periodDuration) {

     periodStartDate.setHours(periodStartDate.getHours());
     periodEndDate.setHours(periodEndDate.getHours());

     const start = periodStartDate.toLocaleDateString('fr-FR').split('/').reverse().join('-') + " " + periodStartDate.toLocaleTimeString();
     const end = periodEndDate.toLocaleDateString('fr-FR').split('/').reverse().join('-') + " " + periodEndDate.toLocaleTimeString()

     await axios.put("https://api.hommes-engages.fr/api/streaks", {startingDate: start, endingDate: end, periodDuration: periodDuration}, {withCredentials:true, CacheControl: "Public"})
     .then(function (response) {
       if (response.status===201) {
         console.log(response);
         updatePage();
       }
       else {
         console.log(response);
       }
     })
     .catch(function (error) {
       console.log(error);
     });

   }

   async function addNewAnalysis(date, volume, numberTotal) {

     date.setHours(date.getHours());

     const dateAnalysis = date.toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }).slice(0, 19).split('/').join('-');


     await axios.put("https://api.hommes-engages.fr/api/analysis", {date: dateAnalysis, volume: volume, totalNumber: numberTotal}, {withCredentials:true, CacheControl: "Public"})
     .then(function (response) {
       if (response.status===201 || response.status===200) {
         console.log(response);
         updatePage();
       }
       else {
         console.log(response);
       }
     })
     .catch(function (error) {
       console.log(error);
     });

   }


   const [type, setType] = React.useState('oneDay');

    const handleChange = (event: SelectChangeEvent) => {
      setType(event.target.value);
    };

    const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  var today = new Date();

  const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>

        <Typography align='left' component="h3" variant="h5" sx={{fontWeight:"bold",mb:2}}>
          Ajout de nouvelles données
        </Typography>

          <Grid container item md={12} xs={12} >
          <FormControl sx={{ mb: 2, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label">Type d'ajout</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant='standard'
          value={type}
          label="Type d'ajout"
          onChange={handleChange}
        >
          <MenuItem value={"oneDay"}>Port sur une journée</MenuItem>
          <MenuItem value={"period"}>Port sur plusieurs jours</MenuItem>
          <MenuItem value={"analysis"}>Résultat d'analyses</MenuItem>
        </Select>
        </FormControl>
        </Grid>


{ type === 'oneDay' &&


<Grid container item md={12} xs={12} justifyContent="flex-start" alignItems="center">
<FormGroup>
      <FormControlLabel control={<Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
      />}
        label = "Aujourd'hui" />
      </FormGroup>
      <Divider orientation="vertical" flexItem sx={{mr:2}}/>

{!checked &&
  <div>

    <DateTimePicker
      renderInput={(props) => <TextField {...props} />}
      label="Date et heure de début"
      value={startingDate}
      onChange={(newValue) => {
        setStartingDate(newValue);
      }}
    />
    <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="Date et heure de fin"
        value={endingDate}
        onChange={(newValue) => {
          setEndingDate(newValue);
        }}
      />
      </div>
    }

    {checked &&
      <div>
      <Grid container item justifyContent="flex-start" alignItems="center">
      <Typography display="inline" variant="subtitle1" sx={{mr:2}}>
      {today.toLocaleDateString("fr-FR",options)}
      </Typography>

        <TimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Heure de début"
          value={startingDate}
          onChange={(newValue) => {
            setStartingDate(newValue);
          }}
        />

        <Grid item sx={{ml:4}}>
                <TimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Heure de fin"
                    value={endingDate}
                    onChange={(newValue) => {
                      setEndingDate(newValue);
                    }}
                  />
              </Grid>


          </Grid>
          </div>
        }
        <Divider orientation="vertical" flexItem sx={{ml:2}}/>

        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{ml:2}}
          onClick={() => addNewEntry(startingDate,endingDate)}
        >
          Ajouter une date de port
        </Button>

      </Grid>
}

{ type === 'period' &&


<Grid container item md={12} xs={12} justifyContent="flex-start" alignItems="center">

        <DatePicker
            renderInput={(props) => <TextField {...props} />}
            label="Date de début"
            value={periodStartDate}
            onChange={(newValue) => {
              setPeriodStartDate(newValue);
            }}
          />

          <Grid item sx={{ml:2}}>
          <DatePicker
              renderInput={(props) => <TextField {...props} />}
              label="Date de fin"
              value={periodEndDate}
              onChange={(newValue) => {
                setPeriodEndDate(newValue);
              }}
            />
            </Grid>


            <TextField
          id="outlined-number"
          label="Heures de port en moyenne par jour"
          type="number"
          sx={{ml:2,minWidth:250}}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0, max: 24 } }}
          value={periodDuration}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPeriodDuration(event.target.value);
          }}
        />


        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{ml:2}}
          onClick={() => addNewPeriod(periodStartDate,periodEndDate,periodDuration)}
        >
          Ajouter une période
        </Button>


      </Grid>
}


{ type === 'analysis' &&


<Grid container item md={12} xs={12} justifyContent="flex-start" alignItems="center">


          <Grid item sx={{ml:2}}>
          <DatePicker
              renderInput={(props) => <TextField {...props} />}
              label="Date d'analyse"
              value={analysisDate}
              onChange={(newValue) => {
                setAnalysisDate(newValue);
              }}
            />
            </Grid>


            <TextField
          id="outlined-number"
          label="Volume récolté (mL)"
          type="number"
          sx={{ml:2,minWidth:250}}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { min: 0, max: 24 } }}
          value={volume}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVolume(event.target.value);
          }}
        />

        <TextField
      id="outlined-number"
      label="Numération totale"
      type="number"
      sx={{ml:2,minWidth:250}}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ inputProps: { min: 0, max: 24 } }}
      value={numberTotal}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberTotal(event.target.value);
      }}
    />


        <Button
          type="button"
          variant="contained"
          color="secondary"
          sx={{ml:2}}
          onClick={() => addNewAnalysis(analysisDate,volume,numberTotal)}
        >
          Ajouter une analyse
        </Button>


      </Grid>
}


    </LocalizationProvider>
  );
}
