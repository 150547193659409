import * as React from 'react';
import Typography from '@mui/material/Typography';
import Copyright from './../components/Copyright';
import { validate } from 'react-email-validator';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from "react-router-dom";
import theme from './../theme';
import { ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './../components/ResponsiveAppBar';


export default function ForgottenPassword() {

  const [reset, setReset] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [emailExists, setEmailExists] = React.useState(false);
  const [resetEmail, setResetEmail] = React.useState('');
  const [isPasswordValid, setIsPasswordValid] = React.useState(false);

  const queryParams = new URLSearchParams(window.location.search)

  const navigate = useNavigate();

  document.title = 'Hommes féministes | Mot de passe oublié';


  React.useEffect(() => {
    if (queryParams.get("token")) {
      setReset(true);
      setToken(queryParams.get("token"))

      const fetchStatus = async () => {
        await axios.get(`https://api.hommes-engages.fr/api/reset_password?token=${queryParams.get("token")}`)
        .then(function (response) {
          if (response.status===200) {
            setIsTokenValid(true);
            setResetEmail(response.data.email);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchStatus()
      // make sure to catch any error
      .catch(console.error);
      }
     }, []);

     const [value, setValue] = React.useState({
       email: '',
       password: ''
     });

     var passwordValidator = require('password-validator');
     var schema = new passwordValidator();
     schema
   .is().min(8)                                    // Minimum length 8
   .is().max(24)                                  // Maximum length 24
   .has().uppercase()                              // Must have uppercase letters
   .has().lowercase()                              // Must have lowercase letters
   .has().digits(1)                                // Must have at least 2 digits
   .has().symbols(1)

     const handleChange = (event) => {
       setValue({
         ...value,
         [event.target.name]: event.target.value,
       });
     };

     const handleEmailChange = (event) => {
       setEmailExists(false);
       handleChange(event);
       checkEmail(event.target.value);
       setIsEmailValid(validate(event.target.value));
     };

     const handlePasswordChange = (event) => { 
       handleChange(event);
       setIsPasswordValid(schema.validate(event.target.value));
     };

     async function checkEmail (email) {
       await axios.get(`https://api.hommes-engages.fr/api/emails/${email}`)
       .then(function (response) {
         if (response.status===200) {
           setEmailExists(true);
         }
       })
       .catch(function (error) {
         console.log(error);
       });
     }

     async function sendEmail(email) {
       let address = "https://api.hommes-engages.fr/api/reset_password";

       setSendingEmail(true);

       await axios.post(address, {email:email})
       .then(function (response) {
         if (response.status===200) {
           setEmailSent(true);
         }
         else {
           setEmailSent(false);
         }
       })
       .catch(function (error) {
         setEmailSent(false);
         console.log(error);
       });
     }

     async function submitPasswordChange(password) {
       let address = "https://api.hommes-engages.fr/api/update_password_from_reset";

       setSendingEmail(true);

       await axios.put(address, {email: resetEmail, password: password, token: token})
       .then(function (response) {
         if (response.status===200) {
           console.log(response);
         }
         else {
           console.log(response);
         }
       })
       .catch(function (error) {
         console.log(error);
       });
     }

     const handleSubmit = (event) => {
       event.preventDefault();
     };


  return (
    <ThemeProvider theme={theme}>
    <ResponsiveAppBar opacity={0.95}/>
    {reset &&
      <Container className="Paper-Container" component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
    <Typography component="h1" variant="h5">
      Réinitialisation de mot de passe
    </Typography>
    {isTokenValid &&
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>>
      <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
        Veuillez renseigner un nouvel mot de passe pour le compte {resetEmail}.
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Mot de passe"
        type="password"
        id="password"
        value={value.password} onChange={handlePasswordChange}
        autoComplete="current-password"
      />
      { value.password.length > 0 &&
      <Typography variant="body2" color={isPasswordValid ? "green" : "red"} sx={{"text-align":"justify"}}>
      {isPasswordValid ? "Le mot de passe remplit tous les critères." : "Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial."}
      </Typography>
    }
    {value.password.length === 0 &&
      <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
        Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial.
      </Typography>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        id="registerButton"
        disabled={!(isPasswordValid)}
        sx={{ mt: 3, mb:1 }}
        onClick={() => submitPasswordChange(`${value.password}`)}
      >
        Inscription
      </Button>
      </Box>}
    {
      !isTokenValid &&
        <div>
        <Typography variant="body2" color="text.secondary" sx={{mt:2,"text-align":"center"}}>
          Le lien n'est plus valide.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          id="registerButton"
          sx={{ mt: 3, mb:1 }}
          onClick={() => navigate("/connexion")}
        >
        Retour à la page de connexion
        </Button>
        </div>
    }

    </Box>
      <Copyright />
    </Container>
  }

  { !reset &&
    <Container className="Paper-Container" component="main" maxWidth="xs">
    <CssBaseline />
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      Demande d'un nouveau mot de passe
    </Typography>

      <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
        Un e-mail vous sera envoyé contenant un lien permettant de réinitialiser votre mot de passe.
      </Typography>
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Adresse e-mail"
      name="email"
      autoComplete="email"
      value={value.email} onChange={handleEmailChange}
      autoFocus
    />
    { !emailExists  &&
    <Typography variant="body2" color="red" sx={{"text-align":"justify"}}>
    {isEmailValid ? "Aucun compte n'est relié à cette adresse email." : "Veuillez renseigner une adresse email valide."}
    </Typography>}

  {emailExists &&
    <Typography variant="body2" color="green" sx={{"text-align":"justify"}}>
      L'adresse email existe.
    </Typography>}
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="secondary"
      id="registerButton"
      disabled={!(isEmailValid && emailExists && !emailSent)}
      sx={{ mt: 3, mb:1 }}
      onClick={() => sendEmail(`${value.email}`)}
    >
      Demander un nouveau mot de passe
    </Button>

    {!(isEmailValid && emailExists) &&
      <Typography variant="body2" color="red" sx={{"text-align":"center",mb:2}}>
        Tous les champs doivent être complétés correctement.
      </Typography>}
      {emailSent && sendingEmail &&
        <div>
        <Typography variant="body2" color="green" sx={{"text-align":"center",mb:2}}>
          L'email a été correctement envoyé.
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          id="registerButton"
          sx={{ mt: 3, mb:1 }}
          onClick={() => navigate("/connexion")}
        >
        Retour à la page de connexion
        </Button>

      </div>}

        {!emailSent && sendingEmail &&
          <Typography variant="body2" color="red" sx={{"text-align":"center",mb:2}}>
            L'email n'a été correctement envoyé, veuillez réessayer.
          </Typography>}
      </Box>
      </Container>}
    </ThemeProvider>
  );
}
