import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Copyright from './../components/Copyright';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material/';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import support from './../support.webp';
import action from './../action.webp';
import noustoutes from './../noustoutes.webp';
import noussommes from './../noussommes.webp';
import planning from './../planning.webp';
import maydee from './../maydee.webp';
import fondation from './../fondation-des-femmes.webp';
import entrelac from './../entrelac.webp';
import thoreme from './../thoreme.webp';
import popol from './../popol.webp';
import Divider from '@mui/material/Divider';
import ResponsiveAppBar from './../components/ResponsiveAppBar';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Sengager() {

  const [expandedUni, setExpandedUni] = React.useState(false);
  const [expandedCourses, setExpandedCourses] = React.useState(false);

  const handleExpandClickUni = () => {
    setExpandedUni(!expandedUni);
  };

  const handleExpandClickCourses = () => {
    setExpandedCourses(!expandedCourses);
  };

  document.title = 'Hommes féministes | S\'engager';


  return (
    <div>
    <ResponsiveAppBar opacity={0.95}/>
    <Grid container justifyContent="center" sx={{mt:5}}>
    <Grid item md={10} xs={12} sx={{mx:{xs:2,md:5}}}>
    <Paper sx={{pt:5,borderRadius:4}}>

    <Typography color="#005151" component="h3" variant="h5" gutterBottom sx={{textAlign:"left",fontWeight:"bold",px:5}}>
      S'engager
    </Typography>
    <Typography sx={{textAlign:'left', color: 'text.secondary',px:5,pb:2 }}>
      Découvrez ici les différentes possibilités d'action pour être allié de la cause féministe en tant qu'homme.
      <br/>
      Et parce que le premier engagement est d'abord de s'informer, retrouvez tous nos articles dans la section <Link href="/sinformer/contraception">"S'informer"</Link>.
    </Typography>

    <Grid container spacing={2} sx={{px:5,pb:5}} justifyContent="space-evenly" alignItems="flex-start" >

    <Grid item xs={12} md={5} container alignItems="center" justify="center">
      <Card className="Homecard" sx={{border: 1, borderColor:"secondary", borderRadius:"5%", boxShadow: 3, '&:hover': {
 boxShadow: "10",display: 'flex', flexDirection: 'column',justifyContent: 'space-between'}}}>
        <CardActionArea component="Button" onClick={handleExpandClickUni}>
        <Grid item alignItems="center">
        <div align='center'>
          <CardMedia
            component="img"
            sx={{width:'75%',pt:5,pb:2}}
            alt="Femme tenant des coeurs dans ses bras"
            image={support}
            loading="lazy"
          />
          </div>
          </Grid>
          <Grid >
          <CardContent>
            <Typography gutterBottom variant={"h5"} component="div">
              Soutenir
            </Typography>
            <Typography component="div" variant={"body2"} color="text.secondary">
              Avant de vous engagez vraiment, vous pouvez soutenir différentes causes.
            </Typography>
          </CardContent>
          </Grid>


          <CardActions disableSpacing>

        <ExpandMore
          expand={expandedUni}
          onClick={handleExpandClickUni}
          aria-expanded={expandedUni}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <Typography sx={{mr:2}}>
        {!expandedUni ? "Découvrir" : "Réduire"}
        </Typography>
      </CardActions>
      <Collapse in={expandedUni} timeout="auto" unmountOnExit>
        <CardContent>
        <Grid item container direction='row' spacing={2} alignItems='center'>

        <Grid item md={3}>

        <div align='center'>
        <CardMedia
          component="img"
          image={entrelac}
          sx={{width:'75%'}}
          alt="Entrelac.coop, la coopérative de la contraception masculine"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          Entrelac.coop, la coopérative de la contraception masculine
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Retrouvez tout sur <Link target="#blank" href="https://entrelac.coop/">entrelac.coop</Link>.<br/>
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
        Devenez sociétaire via <Link target="#blank"  href="https://entrelac.coop/devenir-societaire/">leur site internet</Link>.
        </Typography>
        </Grid>


                <Grid item md={12}>
                <Divider flexItem />
                </Grid>


        <Grid item md={3}>

        <div align='center'>
        <CardMedia
          component="img"
          image={fondation}
          sx={{width:'85%'}}
          alt="Fondation des Femmes"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          Fondation des Femmes
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Retrouvez tout sur <Link target="#blank" href="https://fondationdesfemmes.org/">fondationdesfemmes.org</Link>.<br/>
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
        Faites un don via <Link target="#blank"  href="https://dons.fondationdefrance.org/FONDATION_DES_FEMMES/~mon-don?_cv=1">leur site internet</Link>.
        </Typography>
        </Grid>


                <Grid item md={12}>
                <Divider flexItem />
                </Grid>

        <Grid item md={3}>
        <div align='center'>
        <CardMedia
          component="img"
          image={noustoutes}
          sx={{width:'75%'}}
          alt="Association Nous Toutes"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          Nous Toutes
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Retrouvez tout sur <Link target="#blank" href="https://noustoutes.org">noustoutes.org</Link>.<br/>
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
        Faites un don via <Link target="#blank" href="https://www.helloasso.com/associations/noustoutes/formulaires/2">HelloAsso</Link>.
        </Typography>
        </Grid>

        <Grid item md={12}>
        <Divider flexItem />
        </Grid>

        <Grid item md={3}>

        <div align='center'>
        <CardMedia
          component="img"
          image={planning}
          sx={{width:'75%'}}
          alt="Planning familial"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          Planning familial
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Retrouvez tout sur <Link target="#blank" href="https://www.planning-familial.org/fr">planning-familial.org</Link>.<br/>
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
        Faites un don via <Link target="#blank"  href="https://www.planning-familial.org/fr/faites-un-don-la-confederation-du-planning-ou-lune-de-nos-associations-departementales-119">leur site internet</Link>.
        </Typography>
        </Grid>

        <Grid item md={12}>
        <Divider flexItem />
        </Grid>

        <Grid item md={3}>

        <div align='center'>
        <CardMedia
          component="img"
          image={popol}
          sx={{width:'75%'}}
          alt="Popol média"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          POPOL, le média qui vous propose un regard féministe sur la politique
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Retrouvez tout sur <Link target="#blank" href="http://www.popol-media.com/">popol-media.com</Link>.<br/>
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
          Soutenez-les via <Link target="#blank"  href="https://www.kisskissbankbank.com/fr/projects/popol-media">KissKissBankBank</Link>.
        </Typography>
        </Grid>



        </Grid>



            </CardContent>
          </Collapse>
            </CardActionArea>
          </Card>
          </Grid>

    <Grid item xs={12} md={5} >
    <Card className="Homecard" sx={{ border: 1, borderColor:"secondary", borderRadius:"5%", boxShadow: 3, '&:hover': {
      boxShadow: "10",display: 'flex', flexDirection: 'column',justifyContent: 'space-between'}}}>
        <CardActionArea component="Button" onClick={handleExpandClickCourses}>
        <div align='center'>
          <CardMedia
            component="img"
            sx={{width:'90%',pt:2}}
            image={action}
            alt="2 personnes travaillant ensemble à un exposé"
            loading="lazy"
          />
          </div>
          <CardContent>
            <Typography component="div" gutterBottom variant={"h5"}>
              Passer à l'action
            </Typography>
            <Typography component="div" variant={"body2"} color="text.secondary">
              Découvrez différents moyens de vous engager pour essayer de faire changer les choses.
            </Typography>
          </CardContent>

          <CardActions disableSpacing>

        <ExpandMore
          expand={expandedCourses}
          onClick={handleExpandClickCourses}
          aria-expanded={expandedCourses}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <Typography sx={{mr:2}}>
        {!expandedCourses ? "Découvrir" : "Réduire"}
        </Typography>
      </CardActions>
      <Collapse in={expandedCourses} timeout="auto" unmountOnExit>
        <CardContent>
        <Grid item container direction='row' spacing={1} alignItems='center'>


        <Grid item md={3}>
        <div align='center'>
        <CardMedia
          component="img"
          image={noussommes}
          sx={{width:'75%'}}
          alt="Association Nous Sommes"
          loading="lazy"
        />
        </div>
        </Grid>
        <Grid item md={9} container direction="column">
        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
          Association Nous Sommes
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
          Vous pouvez participer aux évènements de l'association Nous Sommes.
        </Typography>
        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
        Vous pouver accéder à la <Link target="#blank" href="https://www.helloasso.com/associations/noussommes">liste des évènements</Link>.
        </Typography>
        </Grid>


        </Grid>

        <Grid sx={{my:2}} item md={12}>
        <Divider flexItem />
        </Grid>


        <Grid item container direction='row' spacing={1} alignItems='center'>

                <Grid item md={3}>
                <div align='center'>
                <CardMedia
                  component="img"
                  image={maydee}
                  sx={{width:'75%'}}
                  alt="Application Maydée de suivi des tâches ménagères"
                  loading="lazy"
                />
                </div>
                </Grid>
                <Grid item md={9} container direction="column">
                <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
                  Application Maydée
                </Typography>
                <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
                  Utilisez cette application pour réfléchir et réduire la charge mentale dans votre couple.
                </Typography>
                <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
                L'application est disponible <Link target="#blank" href="https://www.maydee.fr/lappli/">via ce lien</Link>.
                </Typography>
                </Grid>


                </Grid>



                <Grid sx={{my:2}} item md={12}>
                <Divider flexItem />
                </Grid>


                <Grid item container direction='row' spacing={1} alignItems='center'>

                        <Grid item md={3}>
                        <div align='center'>
                        <CardMedia
                          component="img"
                          image={thoreme}
                          sx={{width:'75%'}}
                          alt="Site internet Thorème sur la contraception masculine"
                          loading="lazy"
                        />
                        </div>
                        </Grid>
                        <Grid item md={9} container direction="column">
                        <Typography color="black" gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
                          Contraception Masculine - Thorème
                        </Typography>
                        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left'}}>
                          Entreprenez dès maintenant le passage à la contraception thermique masculine !
                        </Typography>
                        <Typography variant={"body"} color="text.secondary" sx={{textAlign:'left',pt:1}}>
                        Toutes les informations sont accesibles <Link target="#blank" href="https://thoreme.com/la-contraception-masculine/">à ce lien</Link>.
                        </Typography>
                        </Grid>


                        </Grid>


        </CardContent>
      </Collapse>

        </CardActionArea>
      </Card>
      </Grid>


    </Grid>
    </Paper>
    <Copyright/>
    </Grid>
    </Grid>
    </div>
  );
}
