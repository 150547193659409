import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ResponsiveAppBar from './../components/ResponsiveAppBar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './../theme';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { animateScroll as scroll } from 'react-scroll';
import { useParams } from "react-router";
import SuccessAlert from './../components/SuccessAlert.js';


export default function Facts() {
  const [cardIndex, setCardIndex] = React.useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const { index } = useParams();

  const cards = [
    {
      title: "L'intersexuation",
      content: (
        <div>De nombreuses personnes dans le monde ne possèdent pas des caractéristiques purement masculines ou féminines.
        <br/> <br/>
        On utilise alors le terme d'intersexuation et donc de personnes intersexe (correspondant au 'I' de l'acronyme LGBTQIA+).
        <br/> <br/>
        Ce n'est pas aussi rare que ce que l'on peut penser. En effet, <b>cela représente environ 1,7% des naissances,
        soit un chiffre comparable aux enfants naissant avec des cheveux roux</b>.
      </div>
    ),
      source: "https://www.amnesty.org/fr/latest/news/2018/10/its-intersex-awareness-day-here-are-5-myths-we-need-to-shatter/"
    },
    {
      title: 'Fausses accusations de viol',
      content: (
        <div>Le taux de fausses accusations de viol généralement admis est de 2 à 10%.
        <br/> <br/>
        De plus, on estime que seuls 5 à 20% des cas d'aggressions sexuelles sont signalés,
        et seulement 3% de ces cas débouchent sur une condamnation de l'agresseur.
        <br/> <br/>
        <b>Il est donc important de croire la victime lorsque celle-ci dit avoir subi une agression sexuelle.</b>
      </div>
    ),
      source: "https://www.washingtonpost.com/news/fact-checker/wp/2014/12/09/the-truth-about-a-viral-graphic-on-rape-statistics/",
    },
    {
      title: 'Violences conjugales',
      content: (
        <div>Dans la grande majorité, ce sont les femmes qui sont victimes de violences conjugales et les hommes qui en sont les auteurs.
        <br/> <br/>
        En effet, en 2020, 87% des victimes de violences conjugales étaient des femmes et des hommes des mis en cause dans 89% des cas.
        <br/> <br/>
        Les cas d'aggressions de la part de femmes comptent également les cas de défense vis-à-vis de violences déjà subies auparavant.
        <b>Le vrai problème vient donc de la violence masculine.</b>
      </div>
    ),
      source: "https://www.interieur.gouv.fr/Interstats/Publications/Interstats-Analyse/Interstats-Analyse-n-53-Les-violences-conjugales-enregistrees-par-les-services-de-securite-en-2021",
    },
    {
      title: `"L'homosexualité est contre-nature"`,
      content: (
        <div>On entend souvent de la part de certaines personnes dire que l'homosexualité serait contre-nature.
        <br/> <br/>
        C'est faux. <b>L'homosexualité a été constatée parmi plus de 1500 espèces du règne animal.</b>
        <br/> <br/>
        Chez certains espèces, notamment parmi les oiseaux, la coparentalité de paires d'individus de même sexe peuvent aller de 20 à 30% de la population !
      </div>
    ),
      source: "https://www.mnhn.fr/fr/l-homosexualite-existe-t-elle-chez-les-animaux",
    },
  ];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }


  React.useEffect(() => {
    document.title = 'Hommes féministes | À propos';

    if (index !== undefined) {
      const parsedIndex = parseInt(index, 10);
      if (!isNaN(parsedIndex) && parsedIndex >= 0 && parsedIndex < cards.length) {
        // Check if 'index' is a valid number within the range of cards
        setCardIndex(parsedIndex);
      } else {
        // Handle invalid index, e.g., redirect to a 404 page or set a default value
        // Here, we're setting it to 0 as a default
        setCardIndex(0);
      }
    } else {
      setCardIndex(getRandomInt(cards.length));
    }// eslint-disable-next-line
  }, []);

  const handleCopyLinkClick = (cardIndex) => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = "https://hommes-feministes.fr/lesaviezvous/"+cardIndex;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 2000);
  };

  const handlePrevClick = () => {
    if (cardIndex > 0) {
      setCardIndex(cardIndex - 1);
      scroll.scrollToTop({ smooth: true });
    }
  };

  const handleNextClick = () => {
    if (cardIndex < cards.length - 1) {
      setCardIndex(cardIndex + 1);
      scroll.scrollToTop({ smooth: true });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar opacity={0.95} />
        { showSuccessAlert && <SuccessAlert message="Le lien a bien été copié"/>}
        <Typography color="black" component="h3" variant="h5" gutterBottom sx={{ mx: {xs:2},my:2,fontWeight: "bold", pt: 2 }}>
          Quelques faits utiles à connaître
        </Typography>
        <Grid container justifyContent="center" sx={{ minHeight:400,flexGrow: 1 }}>
          <Grid item container xs={12} md={9} sx={{ mx: { xs: 3 }, display: 'flex', justifyContent: 'center', alignItems:"flex-end" }}>
            <Paper style={{ minHeight: '100%', width: '100%', maxWidth: '600px', textAlign: 'center', padding: '20px' }}>
              <Typography color="black" component="h3" variant="h5" gutterBottom sx={{ fontWeight: "bold", pt: 2 }}>
                {cards[cardIndex].title}
              </Typography>
              <Typography color="black" component="p" sx={{mt:2,textAlign:'center'}}>
                {cards[cardIndex].content}
              </Typography>
              <Grid item container xs={12} md={12} justifyContent="space-between" sx={{mt:2}}>
              <Grid item>
              <IconButton color="secondary" aria-label="copier le lien" sx={{borderRadius:0}} onClick={() => handleCopyLinkClick(cardIndex)}>
                <LinkIcon />
                <Typography color="secondary" component="p" sx={{ml:1}}>
                Copier le lien
                </Typography>
              </IconButton>
              </Grid>
              <Grid item>
              <Typography color="black" >
                <Link target="_blank" href={cards[cardIndex].source}>Source</Link>
              </Typography>
              </Grid>
              </Grid>

                <IconButton onClick={handlePrevClick} disabled={cardIndex === 0}>
                  <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={handleNextClick} disabled={cardIndex === cards.length - 1}>
                  <ArrowForwardIcon />
                </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
