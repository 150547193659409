import DOMPurify from "dompurify";


const myHTMLFirstPart = `<div style="fontWeight:'bold',mt:2,ml:3,textAlign:'left'"><h1>Qu'est-ce-que la culture du viol ?</h1>
<p>La culture du viol est un concept complexe et troublant qui affecte profond&eacute;ment notre soci&eacute;t&eacute;. Bien que ce ph&eacute;nom&egrave;ne concerne tous les genres, il est essentiel que les hommes prennent conscience de leur r&ocirc;le dans sa perp&eacute;tuation. Dans cet article, nous explorerons la culture du viol, ses manifestations et les actions concr&egrave;tes que les hommes peuvent entreprendre pour y mettre fin.</p>
<h3>Qu'est-ce que la culture du viol ?</h3>
<p>La culture du viol fait r&eacute;f&eacute;rence &agrave; un ensemble de normes, de croyances et de comportements qui minimisent, normalisent et tol&egrave;rent les agressions sexuelles. Elle cr&eacute;e un environnement o&ugrave; les victimes sont bl&acirc;m&eacute;es, o&ugrave; la violence sexuelle est banalis&eacute;e et o&ugrave; les agresseurs &eacute;chappent souvent aux cons&eacute;quences de leurs actes. Comprendre la culture du viol est le premier pas vers sa d&eacute;construction.</p>
<h3>Les cons&eacute;quences de la culture du viol</h3>
<p>La culture du viol a des cons&eacute;quences d&eacute;vastatrices pour les victimes, mais elle affecte &eacute;galement l'ensemble de la soci&eacute;t&eacute;. Les femmes vivent dans la peur constante d'&ecirc;tre agress&eacute;es, ce qui limite leur libert&eacute; et leur autonomie. De plus, la culture du viol perp&eacute;tue des notions toxiques de masculinit&eacute;, o&ugrave; la domination et la violence sont glorifi&eacute;es, ce qui peut nuire aux relations et au bien-&ecirc;tre des hommes eux-m&ecirc;mes.</p>
<h3>D&eacute;construire la culture du viol</h3>
<p>Il est essentiel que les hommes prennent leurs responsabilit&eacute;s pour d&eacute;construire la culture du viol. Voici quelques actions concr&egrave;tes qu'ils peuvent entreprendre :</p>
<ul>
<li>&Eacute;ducation et remise en question : Informez-vous sur les notions de consentement, de respect et de relations saines. Remettez en question les st&eacute;r&eacute;otypes de genre qui contribuent &agrave; la culture du viol.</li>
<li>Promouvoir le consentement : Sensibilisez les autres hommes &agrave; l'importance du consentement et encouragez des conversations ouvertes sur ce sujet. Soyez un exemple de respect des limites et d'&eacute;coute active.</li>
<li>Soutenir les victimes : Croyez et soutenez les victimes d'agression sexuelle. &Eacute;vitez de remettre en question leur t&eacute;moignage et encouragez-les &agrave; rechercher l'aide appropri&eacute;e. &Eacute;vitez de participer &agrave; la diffusion de rumeurs ou de jugements qui bl&acirc;ment les victimes.</li>
<li>Agir en tant que mod&egrave;les positifs : Soyez un mod&egrave;le positif de masculinit&eacute; en rejetant la violence et les attitudes sexistes. Intervenez si vous &ecirc;tes t&eacute;moin de comportements inappropri&eacute;s ou d'agressions.</li>
<li>L'importance de la communication et de l'&eacute;ducation continue: Pour mettre fin &agrave; la culture du viol, il est essentiel d'encourager une communication ouverte et honn&ecirc;te entre les hommes.</li>
<li>Participez &agrave; des discussions, des ateliers ou des groupes de sensibilisation qui abordent la question de la violence sexuelle et de la culture du viol.</li>
<li>&Eacute;duquez-vous continuellement et encouragez les autres hommes &agrave; faire de m&ecirc;me.</li>
</ul>
<p><br />La culture du viol est un probl&egrave;me social complexe qui n&eacute;cessite l'implication et l'action des hommes. En comprenant les manifestations de la culture du viol et en prenant des mesures concr&egrave;tes pour la d&eacute;construire, les hommes peuvent contribuer &agrave; cr&eacute;er une soci&eacute;t&eacute; plus s&ucirc;re, plus &eacute;galitaire et exempte de violences sexuelles. La responsabilit&eacute; collective est n&eacute;cessaire pour mettre fin &agrave; la culture du viol et construire un avenir o&ugrave; le respect mutuel et le consentement sont les fondements de nos relations.</p></div>`;



const mySafeHTMLFirstPart = DOMPurify.sanitize(myHTMLFirstPart);


export default function CultureDuViol () {
  document.title = "Hommes Féministes | Qu'est-ce-que la culture du viol ?";
  return(
  <div>
  <div dangerouslySetInnerHTML={{ __html: mySafeHTMLFirstPart }}/>
  </div>
);}
