import DOMPurify from "dompurify";


const myHTMLFirstPart = `<div style="fontWeight:'bold',mt:2,ml:3,textAlign:'left'"><h1>Le patriarcat : définition et impacts sur la société</h1>
<p>Le patriarcat est un syst&egrave;me social qui favorise la sup&eacute;riorit&eacute; masculine et limite les opportunit&eacute;s des femmes. Il est profond&eacute;ment ancr&eacute; dans notre soci&eacute;t&eacute; depuis des si&egrave;cles. </p>
<h3>Comment touche-t-il la soci&eacute;t&eacute; ?</h3>
<p>Le patriarcat se manifeste de diff&eacute;rentes mani&egrave;res, cr&eacute;ant des in&eacute;galit&eacute;s entre les sexes dans de nombreux domaines de la vie. Au travail, les femmes font face &agrave; des discriminations salariales, &agrave; des plafonds de verre et &agrave; des st&eacute;r&eacute;otypes qui limitent leurs opportunit&eacute;s de carri&egrave;re. Dans les foyers, la violence domestique est une r&eacute;alit&eacute; persistante qui affecte principalement les femmes. Les attentes sociales strictes li&eacute;es &agrave; la masculinit&eacute; et &agrave; la f&eacute;minit&eacute; cr&eacute;ent un cadre rigide qui limite l'expression de soi et le choix de vie des individus.</p>
<h3>Les cons&eacute;quences du patriarcat sur les hommes</h3>
<p>Bien que le patriarcat favorise les hommes en termes de privil&egrave;ges, il comporte &eacute;galement des cons&eacute;quences n&eacute;fastes pour eux. Les attentes rigides li&eacute;es &agrave; la masculinit&eacute; peuvent entra&icirc;ner une pression sociale &eacute;crasante, une limitation des &eacute;motions et une exclusion des soins et de la parentalit&eacute;. Les hommes sont souvent enferm&eacute;s dans des r&ocirc;les dominants qui peuvent nuire &agrave; leur bien-&ecirc;tre &eacute;motionnel et relationnel. En remettant en question ces attentes, les hommes peuvent s'&eacute;panouir dans des expressions plus diverses de la masculinit&eacute; et se lib&eacute;rer des contraintes patriarcales.</p>
<h3>L'engagement masculin pour l'&eacute;galit&eacute; des sexes</h3>
<p>Les hommes jouent un r&ocirc;le essentiel dans la lutte contre le patriarcat et la promotion de l'&eacute;galit&eacute; des sexes. En tant qu'alli&eacute;s f&eacute;ministes, ils peuvent remettre en question les attitudes et les comportements sexistes dans leur propre vie et dans la soci&eacute;t&eacute; en g&eacute;n&eacute;ral. Soutenir les femmes dans leurs luttes pour l'&eacute;galit&eacute;, tant sur le plan individuel que collectif, est &eacute;galement crucial pour progresser vers une soci&eacute;t&eacute; plus &eacute;galitaire. Les hommes peuvent &eacute;galement s'impliquer dans des discussions sur la masculinit&eacute; et la remise en question des normes restrictives de genre.</p>
<p>Il existe de nombreuses actions concr&egrave;tes que les hommes f&eacute;ministes peuvent entreprendre pour d&eacute;construire le patriarcat. Cela comprend l'&eacute;ducation et la prise de conscience de leurs propres privil&egrave;ges, l'amplification des voix des femmes et des minorit&eacute;s de genre, et la promotion de la diversit&eacute; et de l'inclusion dans tous les domaines de la vie.</p></div>`;

const mySafeHTMLFirstPart = DOMPurify.sanitize(myHTMLFirstPart);

export default function Patriarcat () {
  document.title = 'Hommes féministes | Le patriarcat : définition et impacts sur la société';
  return(
  <div>
  <div dangerouslySetInnerHTML={{ __html: mySafeHTMLFirstPart }}/>
  </div>
);}
