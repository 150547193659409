import * as React from 'react'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideMenu from './../components/SideMenu';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Copyright from './../components/Copyright';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { ThemeProvider } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import theme from './../theme';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SuccessAlert from './../components/SuccessAlert';
import ErrorAlert from './../components/ErrorAlert';
import validateToken from './../requests/ValidateToken';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from './../components/ResponsiveAppBar';

const MySettings = () => {
  document.title = 'Hommes féministes | Mes paramètres';
  const settingsLogged = ['Mon profil', 'Mes paramètres', 'Ma contraception'];
  const pagenumber = 1;
  const [areChangesOK, setAreChangesOK] = React.useState(false);
  const [areChangesKO, setAreChangesKO] = React.useState(false);
  const [deleteOK, setDeleteOK] = React.useState(false);
  const [deleteKO, setDeleteKO] = React.useState(false);
  const [hasSomethingChanged, setHasSomethingChanged] = React.useState(false);
  const [status, setStatus] = React.useState(0);
  const [contracepted, setContracepted] = React.useState(0);
  const [method, setMethod] = React.useState('');
  const [contraception, setContraception] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleDelete() { 
    handleClose();
    await axios.delete("https://api.hommes-engages.fr/api/delete_account", {withCredentials:true, CacheControl: "Public"})
    .then(function (response) {
      if (response.status===204) {
        setDeleteOK(true);
        navigate('/');
      }
      else {
        setDeleteKO(true);
        console.log(response);
      }
    })
    .catch(function (error) {
      setDeleteKO(true);
      console.log(error.toJSON());
    });
  }

  const handleChangeDropdown = (event) => {
    setMethod(event.target.value);
    setHasSomethingChanged(true);
  };

  const handleCheckedValues = (checkStatus) => {
    if (checkStatus) {
      setContracepted(1);
    }
    else {
      setContracepted(0);
    }
  }

  const handleSwitch = (event) => {
    setContraception(event.target.checked);
    handleCheckedValues(event.target.checked);
    setHasSomethingChanged(true);
  }

  React.useEffect(() => {

      setAreChangesOK(false);
      setAreChangesKO(false);

      const checkToken = async () => { 
        await validateToken({status, setStatus});
      }

      checkToken()
      // make sure to catch any error
      .catch(console.error);

      const fetchSettingsInfo = async () => {

        await axios.get("https://api.hommes-engages.fr/api/me",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            setMethod(response.data[0].contraceptionMethod);
            setContracepted(response.data[0].contracepted);
            if (response.data[0].contracepted===0) {
              setContraception(false);
            }
            if (response.data[0].contracepted===1) {
              setContraception(true);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      }
      fetchSettingsInfo()
      // make sure to catch any error
      .catch(console.error);
      // eslint-disable-next-line
     }, []);

  async function modifySettings(contraception, method) {
    await axios.put("https://api.hommes-engages.fr/api/update_settings", {contracepted: contracepted, contraceptionMethod: method}, {withCredentials:true, CacheControl: "Public"})
    .then(function (response) {
      if (response.status===200) {
        setAreChangesOK(true);
        setHasSomethingChanged(false)
      }
      else {
        setAreChangesKO(true);
        console.log(response);
      }
    })
    .catch(function (error) {
      setAreChangesKO(true);
      console.log(error.toJSON());
    });
  }

    return (
      <div>
      <ResponsiveAppBar opacity={0.95}/>
      { status !== 200 &&
        <div>
        <h2>Vous devez être connecté.e pour accéder à cette page.
        </h2>
        </div>
      }

      {status===200 &&
        <div>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2} sx={{width:0.95}}>
          <Grid sx={{display:{xs:'none',md:'flex'}}} item md={2}>
            <SideMenu pages={ settingsLogged } pagenumber={ pagenumber }/>
          </Grid>
          <Grid item md={9} xs={12}>
            <Paper sx={{textAlign:"left",minHeight:300,mx:5,my:5,px:5,backgroundColor:'#FFFFFF',border:1,borderColor:'#000000',borderRadius:5}}>
            <ThemeProvider theme={theme}>
            <CardHeader
              avatar={
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <ManageAccountsOutlinedIcon />
                </Avatar>
              }
              title="Mes paramètres" titleTypographyProps={{variant:"h5",color:"secondary.main"}}
              subheader="Modifiez ici les paramètres liés à votre profil"
            />
            { areChangesOK && <SuccessAlert message="Vos paramètres ont été modifiés avec succès." />}
            { areChangesKO && <ErrorAlert message="Une erreur est survenue, veuillez réessayer." />}

                  <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" xs={12} sx={{mt:2}}>
                  <Grid item xs={12} md={9}>
                  <Typography display={"inline"} variant="body1" color="text.secondary">
                  <u>Je me contracepte :</u>
                  </Typography>
                  </Grid>
                  <Typography display="inline" sx={{ml:2,fontWeight:"bold",color:"secondary.main"}}>{contraception ? 'Oui ! 🚀' : 'Non ... 😢'}</Typography>
                  <Switch
                      checked={contraception} onChange={handleSwitch}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name="contraception"
                      sx={{ml:2}}
                    />
                  </Grid>

                    {contraception &&
                      <Grid container direction="row" alignItems="center" justifyContent="space-between" xs={12} sx={{mt:2}}>
                      <Grid item xs={12} md={5} sx={{mt:2}}>
                      <Typography display="inline" variant="body1" color="text.secondary" sx={{mt:2}}>
                      <u>Je sélectionne ma méthode de contraception :</u>
                      </Typography>

                      <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
                      Cette option nous servira à vous afficher des interfaces de gestion de votre contraception.
                      </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} textAlign="right" sx={{mt:2}}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Méthode</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={method}
                          label="Méthode"
                          onChange={handleChangeDropdown}
                          sx={{minWidth:"300px"}}
                        >
                          <MenuItem value={"thermique"}>Méthode thermique (Anneau, slip, ...)</MenuItem>
                          <MenuItem value={"hormonale"}>Méthode hormonale (injection de testostérone)</MenuItem>
                          <MenuItem value={"preservatif"}>Préservatif</MenuItem>
                          <MenuItem value={"vasectomie"}>Vasectomie</MenuItem>
                        </Select>
                      </FormControl>
                      </Grid>
                      </Grid>
                    }

                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={!hasSomethingChanged}
                      onClick={() => modifySettings(`${contraception}`,`${method}`)}
                    >
                      Sauvegarder les changements
                    </Button>

                </Box>
            </ThemeProvider>
            </Paper>

              <Paper sx={{textAlign:"justify",mx:5,my:5,px:5,backgroundColor:'#FFFFFF',border:1,borderColor:'#000000',borderRadius:5}}>
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Grid item xs={12} md={5} textAlign="left" sx={{mt:2}}>
              <Typography display="inline" variant="body1" color="text.secondary">
              <u>Suppression du compte :</u>
              </Typography>
              </Grid>
              <Grid item xs={12} md={6} textAlign="right" sx={{mt:2}}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleClickOpen}
              >
                Supprimer son compte
              </Button>
              <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Voulez-vous vraiment supprimer votre compte ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Toutes les données liées à votre compte seront supprimées. Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      </Grid>
      </Grid>

              </Paper>
              { deleteOK && <SuccessAlert message="Votre compte a bien été supprimé." />}
              { deleteKO && <ErrorAlert message="Une erreur est survenue, veuillez réessayer." />}

              </Grid>
              </Grid>
              </Box>
              <Copyright/>
        </div>}
        </div>
    )
}

export default MySettings
