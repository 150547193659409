const articlesInfos = [
  {
    key: 'contraception',
    value: 0,
    title: 'La contraception masculine',
    sources: [{
      title: "La contraception : une charge mentale pour les femmes",
      link: "https://www.santemagazine.fr/actualites/actualites-traitement/la-contraception-une-charge-mentale-pour-les-femmes-306304",
    },
    {
      title: "Association pour la Recherche et le Développement de la Contraception Masculine (ARDECOM)",
      link: "https://www.contraceptionmasculine.fr/",
    }
  ],
  addedInfos: [{
    title: "Les contraceptés : enquête sur le dernier tabou de Guillaume Daudin et Stéphane Jourdain",
    type: "book",
    link: "https://www.placedeslibraires.fr/livre/9782368464304-les-contraceptes-enquete-sur-le-dernier-tabou-guillaume-daudin-stephane-jourdain/",
  },
  {
    title: " L'homme sous pilule : l'itinéraire d'un couple à la recherche de sa contraception d'Anne-Sophie Delcour (Scénario) et Lucy Macaroni (Dessins)",
    type: "book",
    link: "https://www.placedeslibraires.fr/livre/9782501146944-l-homme-sous-pilule-l-itineraire-d-un-couple-a-la-recherche-de-sa-contraception-anne-sophie-delcour-lucy-macaroni/",
  },
  {
    title: "Association pour la Recherche et le Développement de la Contraception Masculine (ARDECOM)",
    link: "https://www.contraceptionmasculine.fr/",
    type: "link",
  },
  {
    title: "Thoreme, l'anneau Andro-Switch",
    link: "https://thoreme.com/lanneau-andro-switch/",
    type: "link",
  },
  {
    title: "Slow Contraception - Un réseau d'information et d'échange pour l'équité contraceptive",
    link: "https://slowcontraception.com//",
    type: "link",
  },
  {
    title: "Compte instagram - Slow Contraception",
    link: "https://www.instagram.com/slowcontraception/",
    type: "insta",
  },
  {
    title: "Entrelac.coop, la coopérative de la contraception masculine",
    link: "https://entrelac.coop/",
    type: "link",
  }
  ]
  },
  {
    key: 'chargementale',
    value: 1,
    title: 'La charge mentale',
    sources: [{
      title: "Charge Mentale : 8 femmes sur 10 seraient concernées",
      link: "https://www.ipsos.com/fr-fr/charge-mentale-8-femmes-sur-10-seraient-concernees",
    },
    {
      title: "La charge mentale, une double peine pour les femmes",
      link: "https://lejournal.cnrs.fr/nos-blogs/dialogues-economiques/la-charge-mentale-une-double-peine-pour-les-femmes",
    },
    {
      title: "Le temps domestique et parental des hommes et des femmes : quels facteurs d'évolutions en 25 ans ?",
      link: "https://www.insee.fr/fr/statistiques/1303232?sommaire=1303240",
    },
    {
      title: "Charge mentale, définition",
      link: "https://www.larousse.fr/dictionnaires/francais/charge/14743/locution?q=charge+mentale#11072849",
    }
  ],
  addedInfos: [{
    title: "Compte Instagram - T'as pensé à ?",
    type: "insta",
    link: "https://www.instagram.com/taspensea/",
  },
  {
    title: "Podcast Les couilles sur la table #3 - Des chaussettes et des hommes",
    type: "podcast",
    link: "https://www.binge.audio/podcast/les-couilles-sur-la-table/des-chaussettes-et-des-hommes",
  },
  {
    title: "Libérées ! le combat féministe se gagne devant le panier de linge sale de Titiou Lecoq",
    type: "book",
    link: "https://www.placedeslibraires.fr/livre/9782253091691-liberees-le-combat-feministe-se-gagne-devant-le-panier-de-linge-sale-titiou-lecoq/",
  },
  {
    title:"L'appli Maydée, suivi des tâches ménagères au quotidien",
    type:"link",
    link:"https://www.maydee.fr/lappli/",
  }
]
},

{
  key: 'masculinite',
  value: 2,
  title: 'La masculinité toxique',
  sources: [{
    title: "Cambridge dictionary - Toxic masculinity",
    link: "https://dictionary.cambridge.org/fr/dictionnaire/anglais/toxic-masculinity",
  },
  {
    title: "La masculinité toxique, un obstacle à la santé mentale des hommes ?",
    link: "https://acsmmontreal.qc.ca/news/la-masculinite-toxique-un-obstacle-a-la-sante-mentale-des-hommes%E2%80%89/",
  },
  {
    title: "What Is Toxic Masculinity?",
    link: "https://www.nytimes.com/2019/01/22/us/toxic-masculinity.html",
  },
  {
    title: "Unlearning Toxic Masculinity",
    link: "https://www.brown.edu/campus-life/health/services/promotion/general-health-social-wellbeing-sexual-assault-dating-violence-get-involved-prevention/unlearning",
  },
  {
    title: "No. 19 : Peut-on être un homme et féministe ?! - Kreatur",
    link: "https://www.arte.tv/fr/videos/102190-036-A/pourquoi-la-masculinite-toxique-nuit-a-tout-le-monde/",
  }
],
addedInfos: [{
  title: "Ces jeunes hommes qui repensent leur masculinité",
  type: "link",
  link: "https://www.lemonde.fr/campus/article/2022/02/08/on-peut-inventer-d-autres-modeles-ces-jeunes-hommes-qui-repensent-leur-masculinite_6112718_4401467.html",
},
{
  title: "The Womanist Podcast - Ep. 22 Masculinité Toxique",
  type: "podcast",
  link: "https://humanitheque.fr/produit/the-womanist-podcast/",
},
{
  title: "On ne naît pas mec de Daisy Letourneur",
  type: "book",
  link: "https://www.placedeslibraires.fr/livre/9782355221521-on-ne-nait-pas-mec-daisy-letourneur/",
},{
  title: " La volonté de changer : les hommes, la masculinité et l'amour de Bell Hooks",
  type: "book",
  link: "https://www.placedeslibraires.fr/livre/9791097088415-la-volonte-de-changer-les-hommes-la-masculinite-et-l-amour-bell-hooks/",
}
]
},

{
  key: 'patriarcat',
  value: 3,
  title: 'Le patriarcat',
  sources: [{
    title: "Rapport annuel 2023 sur l'état des lieux du sexisme en France",
    link: "https://www.haut-conseil-egalite.gouv.fr/IMG/pdf/hce_-_rapport_annuel_2023_etat_du_sexisme_en_france.pdf",
  },
  {
    title: "Ces jeunes hommes qui repensent leur masculinité - Le Monde",
    link: "https://www.lemonde.fr/campus/article/2022/02/08/on-peut-inventer-d-autres-modeles-ces-jeunes-hommes-qui-repensent-leur-masculinite_6112718_4401467.html"
  },
  {
    title: "Le patriarcat, c'est quoi ? - Tilt!",
    link: "https://www.tilt.fr/articles/le-patriarcat-cest-quoi"
  }
],
addedInfos: [{
  title: "Réinventer l'amour de Mona Chollet",
  type: "book",
  link: "https://www.placedeslibraires.fr/livre/9782355221743-reinventer-l-amour-comment-le-patriarcat-sabote-les-relations-heterosexuelles-mona-chollet/",
},
{
  title: "La volonté de changer de bell hooks",
  type: "book",
  link: "https://www.placedeslibraires.fr/livre/9791097088415-la-volonte-de-changer-les-hommes-la-masculinite-et-l-amour-bell-hooks/"
},
{
  title: "Ce que le patriarcat fait à l'amour - Les couilles sur la table",
  type: "podcast",
  link: "https://www.binge.audio/podcast/les-couilles-sur-la-table/ce-que-le-patriarcat-fait-a-lamour-in-english",
},
{
  title: "Faut-il abolir le patriarcat ? - France Culture",
  type: "podcast",
  link: "https://www.radiofrance.fr/franceculture/podcasts/les-chemins-de-la-philosophie/faut-il-abolir-le-patriarcat-2256237"
},
{
  title: "Patriarcat. La domination masculine a-t-elle toujours existé ? - France Culture",
  type: "podcast",
  link: "https://www.radiofrance.fr/franceculture/podcasts/signes-des-temps/patriarcat-la-domination-masculine-a-t-elle-toujours-existe-8096810"
},
{
  title: "C'est quoi le patriarcat ? Définition et origines - Brut",
  type: "video",
  link: "https://www.brut.media/fr/news/c-est-quoi-le-patriarcat-definition-et-origines-10d83802-dfcf-4975-83e3-4c0f87883462"
}
]
},

{
  key: 'cultureduviol',
  value: 4,
  title: 'La culture du viol',
  sources: [
    {
    title: "16 façons de lutter contre la culture du viol - ONU Femmes",
    link: "https://www.unwomen.org/fr/news/stories/2019/11/compilation-ways-you-can-stand-against-rape-culture",
  },
  {
    title: `"Culture du viol" : derrière l'expression, une arme militante plutôt qu'un concept`,
    link: "https://www.radiofrance.fr/franceculture/culture-du-viol-derriere-l-expression-une-arme-militante-plutot-qu-un-concept-5838149"
  },
  {
    title: "Rape culture isn't a myth. It's real, and it's dangerous - Vox",
    link: "https://www.vox.com/2014/12/15/7371737/rape-culture-definition"
  }
],
addedInfos: [
  {
  title: "Une culture de viol à la française de Valérie Rey-Robert",
  type: "book",
  link: "http://www.editionslibertalia.com/catalogue/hors-collection/une-culture-du-viol-a-la-francaise",
},
{
  title: "En finir avec la culture du viol de Noémie Renard",
  type: "book",
  link: "https://www.placedeslibraires.fr/livre/9782363832986-en-finir-avec-la-culture-du-viol-noemie-renard/"
},
{
  title: "La culture du viol, c'est quoi ? - Kreatur #7 sur Arte",
  type: "video",
  link: "https://www.arte.tv/fr/videos/088128-003-A/la-culture-du-viol-c-est-quoi/"
}
]
}

];
export { articlesInfos };
