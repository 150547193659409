import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import banner from './../banner.jpg';
import getinvolved from './../getinvolved.png';
import getinformed from './../getinformed.png';
import homeImage from './../homeImage.webp';
import {Link} from 'react-router-dom';
import theme from './../theme';
import {Link as LinkMUI} from "@mui/material";
import { ThemeProvider } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material/';
import Copyright from './../components/Copyright';
import Divider from '@mui/material/Divider';
import ResponsiveAppBar from './../components/ResponsiveAppBar';

const Home = () => {
  document.title = 'Les hommes engagés | Définitions et engagements';
    return (
      <ThemeProvider theme={theme}>
        <Paper className="Paper-Container">
            <CardMedia image={banner} className="App-Banner" alt="Fleur rose au milieu de plantes vertes" sx={{opacity:1}}>
            <Grid container>
            <Grid item md={12} xs={12}>
              <ResponsiveAppBar opacity={0.9}/>
            </Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    position: 'relative',
                    px: { xs: 3, md: 12 },
                    py: { xs: 3, md: 6 },
                    pr: { md: 0 },
                  }}
                >
                <Typography color="white" component="h1" variant={"h3"} gutterBottom sx={{textAlign:"left",fontWeight:"bold"}}>
                  Bienvenue
                </Typography>
                  <Typography component="div" variant={"h5"} color="white" paragraph sx={{textAlign:"justify"}}>
                    Un site internet <Typography component="div" display="inline" variant={"h5"} color="white" paragraph sx={{textAlign:"left",textDecoration:"underline",fontWeight:"bold"}}>entièrement gratuit</Typography> qui regroupe un certain nombre de ressources à destination des hommes pour lutter contre les inégalités de genre. Pour s'informer et s'engager.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            </CardMedia>
          </Paper>
          <Grid container spacing={2} sx={{p:5}} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12} md={4}>
            <Card className="Homecard" sx={{ maxWidth: 445, border: 1, borderColor:"secondary", borderRadius:"5%", boxShadow: 3, '&:hover': {
       boxShadow: "20"}}}>
              <CardActionArea component={Link} to="\/sinformer\/contraception">
                <CardMedia
                  component="img"
                  height="300"
                  image={getinformed}
                  alt="Homme regardant des informations sur un tableau de bord"
                />
                <CardContent>
                  <Typography gutterBottom variant={"h5"} component="h2">
                    S'informer
                  </Typography>
                  <Typography component="div" variant={"body2"} color="text.secondary">
                    Naviguez parmi différentes ressources pour découvrir davantage d'information sur la cause féministe !
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>

          <Grid item xs={12} md={4} >
          <Card className="Homecard" sx={{ maxWidth: 445, border: 1, borderColor:"secondary", borderRadius:"5%", boxShadow: 3, '&:hover': {
            boxShadow: "20"}}}>
              <CardActionArea component={Link} to="/sengager">
                <CardMedia
                  component="img"
                  height="300"
                  image={getinvolved}
                  alt="Groupe qui travaille ensemble sur un tableau de bord"
                />
                <CardContent>
                  <Typography component="h2" gutterBottom variant="h5">
                    S'engager
                  </Typography>
                  <Typography component="div" variant={"body2"} color="text.secondary">
                    Découvrir tous les moyens d'action possibles, y compris des petits gestes qui peuvent faire toute la différence !
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>

          </Grid>

          <Paper className="Paper-Container" sx={{backgroundColor:"secondary.main"}}>
          <Typography color="white" component={"h3"} variant={"h5"} gutterBottom sx={{textAlign:"center",pt:5,pb:1,fontWeight:'bold'}}>
            Pourquoi ce site internet ?
          </Typography>




          <Paper className="Paper-Container" sx={{mx:6,backgroundColor:"white",borderRadius:5,mb:6}}>
          <Grid container direction='row' justifyContent="center" alignItems="center">
          <Grid item md={7}>
          <Typography component="div" variant={"div"} color="black" paragraph sx={{m:4,alignItems:'center',textAlign:"justify"}}>
            Les mouvements féministes ont joué et jouent encore un rôle crucial dans la reconnaissance des droits des femmes.
            <br/> <br/>
            Au-delà de cet aspect, le féminisme vise également à encourager les hommes à remettre en question la société actuelle et à participer activement à la lutte pour l'égalité.
            <br/> <br/>
            <b>Dans cet optique, ce site internet s'adresse en priorité aux hommes, pour les sensibiliser aux enjeux du féminisme actuel.</b>
            <br/> <br/>
            L'idée est donc d'informer du mieux possible, pour favoriser l'engagement des hommes autour de ces thématiques,
            ce qui serait bénéfique au final à toute la société.
            <br/>
            <b>Il est donc déjà possible d'utiliser cette plateforme comme outil de suivi de contraception masculine thermique, et d'autres fonctionnalités pourront également voir le jour par la suite.</b>
            <br/> <br/>
            Ce site internet a été créé par un homme, qui n'est donc pas nécessairement le mieux placé pour informer sur ces sujets.
            <br/>
            Le parti pris ici étant de ne pas demander uniquement aux femmes de faire le travail de sensibilisation auprès des hommes, car c'est aussi à eux de se responsabiliser.
            <br/> <br/>
            <b>Les informations sont basées sur différentes sources de contenus féministes déjà publiés : essais, articles, podcasts, vidéos, etc.
            Si une information est imprécise, n'hésite pas à en faire part à <a href="mailto:des.hommes.feministes@gmail.com">des.hommes.feministes@gmail.com</a>.</b>
          </Typography>
          </Grid>
          <Grid item md={5} >
          <img src={homeImage} alt="3 maisons de ville côte à côte" width='100%'/>
          </Grid>
          </Grid>
          </Paper>

          <Paper className="Paper-Container" sx={{backgroundColor:"primary.main"}}>
          <Typography color="white" component={"h3"} variant={"h5"} gutterBottom sx={{textAlign:"center",pt:5,fontWeight:'bold'}}>
            Qu'attendez-vous ?
          </Typography>
          <Grid container columns={10} spacing={2} sx={{p:2}} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={6} md={2} sx={{ maxWidth: 445, borderRadius:"5%", boxShadow: 1, mb:{xs:2}, '&:hover': {
            boxShadow: "10"}}}>
          <Typography component="div" variant={"h5"} align="center" gutterBottom sx={{color:'secondary.main',fontWeight:'bold'}}>
          En France, en 2020, <Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>87% des victimes
          </Typography> de violences conjugales sont des <Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>femmes.
          </Typography>
          </Typography>
          </Grid>
            <Divider sx={{ display: { xs: 'none', md: 'block' },background:'white' }} orientation="vertical" flexItem/>
          <Grid item xs={6} md={2} sx={{ maxWidth: 445, borderRadius:"5%", boxShadow: 1, mb:{xs:2}, '&:hover': {
            boxShadow: "10"}}}>
          <Typography component="div" variant={"h5"} align="center" gutterBottom sx={{color:'secondary.main',fontWeight:'bold'}}>
          Au cours de leur vie,<Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>14,5 % des femmes
          </Typography>déclarent avoir vécu
          <Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>au moins une forme d’agression sexuelle.
          </Typography>
          </Typography>
          </Grid>
          <Divider sx={{ display: { xs: 'none', md: 'block' },background:'white' }} orientation="vertical" flexItem/>
          <Grid item xs={6} md={2} sx={{ maxWidth: 445, borderRadius:"5%", boxShadow: 1, mb:{xs:2}, '&:hover': {
            boxShadow: "10"}}}>
          <Typography component="div" variant={"h5"} align="center" gutterBottom sx={{color:'secondary.main',fontWeight:'bold'}}>
          En France, en 2019, <Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>l'écart salarial
          </Typography> hommes/femmes était en moyenne de
          <Typography component="div" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>de 22 %
          </Typography>
          </Typography>
          </Grid>
          <Divider  sx={{ display: { xs: 'none', md: 'block' },background:'white' }} orientation="vertical" flexItem/>
          <Grid item xs={6} md={2} sx={{ maxWidth: 445, borderRadius:"5%", boxShadow: 1, mb:{xs:2},'&:hover': {
            boxShadow: "10"}}}>
          <Typography component="div" variant={"h5"} align="center" gutterBottom sx={{color:'secondary.main',fontWeight:'bold'}}>
          En France, en 2010, <Typography component="div" align="center" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>le travail domestique
          </Typography> était en moyenne
          <Typography component="div" align="center" variant={"h5"} sx={{color:'#f8f8f8',fontWeight:'bold'}}>74 % plus élevé pour les femmes.
          </Typography>
          </Typography>
          </Grid>
          </Grid>
          <Typography component="div" color="white" variant={"h5"} gutterBottom sx={{pt:2, mx:{xs:2}, mb:{xs:2}, textAlign:"center",fontWeight:'bold'}}>
            Informez-vous et engagez-vous !
          </Typography>
          <Typography component="div" variant={"body2"} color="secondary" gutterBottom sx={{textAlign:"right",pb:2,mr:2}}>
            Sources: <LinkMUI href="https://www.insee.fr/fr/statistiques/6047789" color="inherit">
    INSEE
  </LinkMUI> et <LinkMUI href="https://www.lemonde.fr/les-decodeurs/article/2017/03/07/les-inegalites-hommes-femmes-en-12-chiffres-et-6-graphiques_5090765_4355770.html" color="inherit">LeMonde.fr</LinkMUI>.
          </Typography>
          </Paper>




</Paper>

          <Copyright/>
      </ThemeProvider>
    )
}

export default Home
