import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {Link} from "@mui/material";
import ResponsiveAppBar from './../components/ResponsiveAppBar';
import screenshot from './../screenshot.png';

export default function About() {
  const [expanded, setExpanded] = React.useState(false);

  document.title = 'Hommes féministes | À propos';





  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
    <ResponsiveAppBar opacity={0.95}/>
    <Grid container justifyContent="center" sx={{mt:5}}>

    <Grid item md={9} xs={12} sx={{mx:{xs:1}}}>
    <Paper>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight:"bold", textAlign:"left" }}>
            Informations sur l'application 🤓
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Toutes les infos sur le développement et le fonctionnement de l'application.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"justify"}}>
            Le site internet a été développé en React JS, avec la librairie Material UI. Elle est déployée via Render.
            <br/> <br/>
            L'application qui calcule les données de suivi de contraception est codée en Node JS. Elle est déployée via Render.
            <br/> <br/>
            La base de données de suivi de contraception est en MySQL, hébergée en France sur <Link href="https://www.clever-cloud.com/fr/">Clever Cloud</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight:"bold", textAlign:"left" }}>
            Pourquoi créer un compte ? 🤔
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Tout ce que vous devez savoir sur la création de compte.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"justify"}}>
            La création d'un compte n'est pour l'instant utile qu'en cas d'utilisation de la méthode de contraception thermique. Une fois connecté, une interface vous permettra de réaliser un suivi de votre contraception.
            </Typography>
            <div align='left'>
              <img width='70%' src={screenshot} alt="Capture d'écran de l'application de suivi de contraception thermique"/>
            </div>
          <Typography sx={{textAlign:"justify",mt:4}}>
            Si vous êtes intéressé par une autre fonctionnalité qui pourrait être utile, n'hésitez pas à nous contacter !
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight:"bold", textAlign:"left", pr:5 }}>Comment gère-t-on les données ? 🗄</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Le stockage des données de votre compte sur l'application.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"justify"}}>
            Des données ne sont collectées qu'en cas de création de compte. Dans ce cas, les seules données enregistrées sont votre nom d'utilisateur, votre adresse e-mail (pour la réinitialisation du mot de passe) et votre mot de passe encodé (nous ne le connaissons pas !).
            Ces données sont enregistrés dans une base de données hébergée en France par <Link href="https://www.clever-cloud.com/fr/">Clever Cloud</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0, fontWeight:"bold", textAlign:"left" }}>
            Comment nous contacter ? ✏️
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            En cas de problèmes sur l'application ou pour proposer des améliorations.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{textAlign:"justify"}}>
            Si vous rencontrez des soucis sur l'application ou si souhaitez transmettre des idées d'amélioration de l'application et de son contenu, merci de contacter <Link href="mailto:des.hommes.feministes@gmail.com">des.hommes.feministes@gmail.com</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Paper>
      </Grid>
      </Grid>
      <div
        className="lbp-metastrat"
        data-placeholder="true"
        data-widget-id="db975d9d7987a66100649485ca5be777"
        data-sdg="[5,10,13,16]"
        data-type="none"
        data-md-type="portrait"
        style={{ textAlign: 'center' }}
        data-test="true"
      ></div>
      <script async src="https://play.labonnepub.com/fr/lbp/5abd4-a6a7d-3f5a4-1d7f5-6e8ce/js/"></script>
    </div>
  );
}
