import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from './../theme';
import Copyright from './../components/Copyright';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import ResponsiveAppBar from './../components/ResponsiveAppBar';


export default function Login() {
  const [value, setValue] = React.useState({
    username: '',
    email: '',
    password: ''
  });

  document.title = 'Hommes féministes | Connexion';

  const [stayLoggedIn, setStayLoggedIn] = React.useState(false);
  const [areCredsValid, setAreCredsValid] = React.useState(true);

  const [showPassword, setShowPassword] = React.useState(false);

  const [showLoader, setShowLoader] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setAreCredsValid(true);
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleStayLoggedIn = (event) => {
    setStayLoggedIn(!event.target.checked);
  }

  const handleStatus = (statusCode) => {
    if (statusCode===200) {
      navigate('/profil');
    }
    if (statusCode >= 400) {
      setAreCredsValid(false);
    }
  }

  async function handleLogin(email, password, stayLoggedIn) {
    let address = "https://api.hommes-engages.fr/api/login";

    setShowLoader(true);

    if (stayLoggedIn) {
      address = address + "?stayLoggedIn=true";
    }

    await axios.post(address, {email:email, password:password},{withCredentials: true, CacheControl: "Public"})
    .then(function (response) {
      handleStatus(response.status)
    })
    .catch(function (error) {
      handleStatus(error.response.status)
      console.log(error);
    });
  }

  return (
    <ThemeProvider theme={theme}>
    <ResponsiveAppBar opacity={0.95}/>
      <Container className="Paper-Container" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail ou nom d'utilisateur"
              name="email"
              value={value.email} onChange={handleChange}
              autoComplete="email"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              id="password"
              value={value.password} onChange={handleChange}
              autoComplete="current-password"

              variant="outlined"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />



            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Se souvenir de moi"
              id="stayLoggedIn"
              value = {stayLoggedIn}
              onChange={handleStayLoggedIn}
            />
            {!areCredsValid &&
              <Typography variant="body2" color="red" sx={{"text-align":"center",mt:2}}>
                L'adresse email et/ou le mot de passe utilisés ne sont pas corrects.
              </Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => handleLogin(`${value.email}`,`${value.password}`,stayLoggedIn)}
            >
              Connexion
            </Button>
            { showLoader &&
              <Box sx={{ display: 'flex', my: 2,
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <CircularProgress />
              </Box>
            }



            <Grid container>
              <Grid item xs>
                <Link href="/mdp_oublie" variant="body2">
                  Mot de passe oublié ?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="/inscription" variant="body2">
                  {"Créer un nouveau compte"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright/>
      </Container>
    </ThemeProvider>
  );
}
