import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SideMenu from './../components/SideMenu';
import Grid from '@mui/material/Grid';
import Copyright from './../components/Copyright';
import WearingsTable from './../components/WearingsTable';
import CollapsedWearingsTable from './../components/CollapsedWearingsTable';
import AddingSingleEntry from './../components/AddingSingleEntry';
import Graph from './../components/Graph';
import Typography from '@mui/material/Typography';
import ResponsiveAppBar from './../components/ResponsiveAppBar';

import validateToken from './../requests/ValidateToken';
import axios from "axios";


const MySettings = () => {
  document.title = 'Hommes féministes | Ma contraception';

  const settingsLogged = ['Mon profil', 'Mes paramètres', 'Ma contraception'];
  const pagenumber = 2;

  const [status, setStatus] = React.useState(0);
  const [method, setMethod] = React.useState('');
  const [contraception, setContraception] = React.useState(false);

  const [streak, setStreak] = React.useState(0);
  const [concentration, setConcentration] = React.useState(0);


  React.useEffect(() => {

      const checkToken = async () => { 
        await validateToken({status, setStatus});
      }

      checkToken()
      // make sure to catch any error
      .catch(console.error);

      const fetchSettingsInfo = async () => {

        await axios.get("https://api.hommes-engages.fr/api/me",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            setMethod(response.data[0].contraceptionMethod);
            if (response.data[0].contracepted===0) {
              setContraception(false);
            }
            if (response.data[0].contracepted===1) {
              setContraception(true);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchSettingsInfo()
            // make sure to catch any error
            .catch(console.error);

      const refreshStreak = async () => {
        await axios.get("https://api.hommes-engages.fr/api/calculateStreaks",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            console.log(response);
            if (response.data[0].status==='ongoing') {
              setStreak(response.data[0].count);
            }
            else {
              setStreak(0);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      }

      refreshStreak()
      // make sure to catch any error
      .catch(console.error);


      const fetchStreak = async () => {

        await axios.get("https://api.hommes-engages.fr/api/streaks?latestStreak=true",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            console.log(response.data.ongoingStreak);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchStreak()
            // make sure to catch any error
            .catch(console.error);


      const fetchAnalysis = async () => {

        await axios.get("https://api.hommes-engages.fr/api/analysis",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            setConcentration(response.data[0].concentration);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchAnalysis()
            // make sure to catch any error
            .catch(console.error);


            // eslint-disable-next-line
           }, []);

  function refreshPage() {
    window.location.reload(false);
  }

    return (
      <div>
      <ResponsiveAppBar opacity={0.95}/>
      { status !== 200 &&
        <div>
        <h2>Vous devez être connecté.e pour accéder à cette page.
        </h2>
        </div>
      }

      { !contraception &&
        <div>
        <h2>Pour accéder à cette page, vous devez avoir activé l'option "Je me contracepte" dans vos paramètres.
        </h2>
        </div>
      }

      {status===200 && contraception && method==="thermique" &&
        <div>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container sx={{width:0.95}}>
          <Grid sx={{display:{xs:'none',md:'flex'}}} item md={2}>
            <SideMenu pages={ settingsLogged } pagenumber={ pagenumber }/>
          </Grid>
          <Grid container item md={10} xs={12} justifyContent="space-between">
          <Grid item md={7} sx={{mt:4}}>
          <Typography align='left' component="h1" variant="h4" sx={{fontWeight:"bold",mb:2}}>
            Ma contraception
          </Typography>
          </Grid>
            <Grid item md={7} xs={12} sx={{mr:{xs:5}}} >
              <Paper sx={{p:5, mt:2,minHeight:500}}>
                <Graph/>
              </Paper>
            </Grid>
            <Grid container item md={4} direction="column" justifyContent="space-between" alignItems="flex-end">
            <Grid item md={5} sx={{mt:2,pr:5,minWidth:"100%"}}>
              <Paper sx={{height:'100%',p:2}} >
              <Typography align='left' component="h1" color="secondary" variant="h6" sx={{fontWeight:"bold",mb:2}}>
                🚀 Série en cours
              </Typography>

              { streak > 90 &&
                <div>
              <Typography align='left' color='green' sx={{fontWeight:"bold",mb:2}}>
                Félicitations, vous en êtes à {streak} jours d'affilée !
              </Typography>
              <Typography align='left'>
                Il est conseillé de réaliser une analyse médicale tous les 3 mois pour s'assurer du bon fonctionnement de la méthode.
              </Typography>
              </div>
            }

            { streak > 0 && streak < 90 &&
              <div>
            <Typography align='left' color='orange' sx={{fontWeight:"bold",mb:2}}>
              Bravo, vous en êtes à {streak} jours d'affilée ! Vous êtes en bon chemin.
            </Typography>
            <Typography align='left'>
              Il faut environ 90 jours de port pour être contracepté, puis réaliser une analyse médicale qui le confirmera.
            </Typography>
            </div>
          }

          { streak === 0 &&
            <div>
          <Typography align='left' color='red' sx={{fontWeight:"bold",mb:2}}>
            Dommage, vous en êtes à {streak} jour d'affilée ... ne baissez pas les bras !
          </Typography>
          <Typography align='left'>
            Il faut environ 90 jours de port pour être contracepté, puis réaliser une analyse médicale qui le confirmera.
          </Typography>
          </div>
        }


              </Paper>
            </Grid>

            <Grid item md={5} sx={{pr:5,minWidth:"100%"}}>
              <Paper sx={{height:'100%',p:2}}>
              <Typography align='left' component="h1" variant="h6" color="secondary" sx={{fontWeight:"bold",mb:2}}>
                🔬 Dernière analyse
              </Typography>

              {concentration > 0 && concentration < 1000000 &&
                <div>
              <Typography align='left' color="green" sx={{fontWeight:"bold",mb:2}}>
                Bravo, votre dernière analyse montre une concentration en spermatozoïdes de {concentration} spermatozoïdes/millilitre. Vous êtes contracepté !
              </Typography>
              <Typography align='left'>
                  L'OMS recommande une concentration inférieure à 1 million de spermatozoïdes par millilitre.
              </Typography>
              </div>
            }

            {concentration > 1000000 &&
              <div>
            <Typography align='left' color="orange" sx={{fontWeight:"bold",mb:2}}>
              Votre dernière analyse montre une concentration en spermatozoïdes de {concentration} spermatozoïdes/millilitre. Vous n'êtes pas considéré comme contracepté.
            </Typography>
            <Typography align='left'>
                L'OMS recommande une concentration inférieure à 1 million de spermatozoïdes par millilitre.
            </Typography>
            </div>
          }

            {concentration === 0 &&
              <div>
            <Typography align='left' color="red" sx={{fontWeight:"bold",mb:2}}>
              Vous n'avez pas renseigné d'analyse médicale, n'hésitez pas à le faire !
            </Typography>
            <Typography align='left'>
                L'analyse de type spermogramme est gratuite sur ordonnance. Parlez-en à votre médecin.
            </Typography>
            </div>
          }



              </Paper>
            </Grid>
            </Grid>
            <Grid item md={12} xs={12} sx={{mr:{xs:5}}} alignItems="center" >
              <Paper sx={{mt:2,p:5}}>
                <AddingSingleEntry  updatePage={refreshPage}/>
              </Paper>
            </Grid>

            <Grid item md={12} xs={12} sx={{mr:{xs:5}}}>
            <Paper sx={{mt:2}}>
            <CollapsedWearingsTable/>
          </Paper>
            </Grid>
            <Grid item md={12} xs={12} sx={{mr:{xs:5}}}>
            <Paper sx={{mt:2}}>
              <WearingsTable/>
            </Paper>
            </Grid>

          </Grid>
        </Grid>
        </Box>
        <Copyright/>
        </div>}
        </div>
    )
}

export default MySettings
