import React from 'react'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SideMenu from './../components/SideMenu';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Copyright from './../components/Copyright';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { ThemeProvider } from '@mui/material/styles';
import theme from './../theme';
import validateToken from './../requests/ValidateToken';
import axios from "axios";
import SuccessAlert from './../components/SuccessAlert';
import ErrorAlert from './../components/ErrorAlert';
import Stack from '@mui/material/Stack';
import ResponsiveAppBar from './../components/ResponsiveAppBar';



const MyProfile = () => {
  const settingsLogged = ['Mon profil', 'Mes paramètres', 'Ma contraception'];
  const pagenumber = 0;
  const [status, setStatus] = React.useState(0);

  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');

  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = React.useState(false);
  const [isNewPasswordOneValid, setIsNewPasswordOneValid] = React.useState(false);
  const [isNewPasswordTwoValid, setIsNewPasswordTwoValid] = React.useState(false);

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPasswordOne, setNewPasswordOne] = React.useState("");
  const [newPasswordTwo, setNewPasswordTwo] = React.useState("");

  const [editMode, setEditMode] = React.useState(false);

  const [profileChangesOK, setProfileChangesOK] = React.useState(false);
  const [passwordChangesOK, setPasswordChangesOK] = React.useState(false);
  const [profileChangesKO, setProfileChangesKO] = React.useState(false);
  const [passwordChangesKO, setPasswordChangesKO] = React.useState(false);

  document.title = 'Hommes féministes | Mon profil';

  React.useEffect(() => {

      const checkToken = async () => { 
        await validateToken({status, setStatus});
      }

      checkToken()
      // make sure to catch any error
      .catch(console.error);

      const fetchProfileInfo = async () => {

        await axios.get("https://api.hommes-engages.fr/api/me",{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            setEmail(response.data[0].email)
            setUsername(response.data[0].username)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchProfileInfo()
      // make sure to catch any error
      .catch(console.error);

     }, []);

     function openEditMode () { 
       setEditMode(true);
     }


     var passwordValidator = require('password-validator');
     var schema = new passwordValidator();
     schema
   .is().min(8)                                    // Minimum length 8
   .is().max(24)                                  // Maximum length 24
   .has().uppercase()                              // Must have uppercase letters
   .has().lowercase()                              // Must have lowercase letters
   .has().digits(1)                                // Must have at least 2 digits
   .has().symbols(1)

   const handleUsernameChange = (event) => { 
     setUsername(event.target.value);
   };

   const handleEmailChange = (event) => { 
    setEmail(event.target.value);
   };

   const handlePasswordChange = (event) => { 
     setIsCurrentPasswordValid(false);
     setCurrentPassword(event.target.value);
     checkPassword(event.target.value);
   };

   const handlePasswordOneChange = (event) => { 
     setNewPasswordOne(event.target.value);
     setIsNewPasswordOneValid(schema.validate(event.target.value));
   };

   const handlePasswordTwoChange = (event) => { 
     setNewPasswordTwo(event.target.value);
     if (event.target.value===newPasswordOne) {
       setIsNewPasswordTwoValid(true);
    }
   };

   async function checkPassword(password) {

     await axios.post("api/verify_password", {password:password}, {withCredentials:true})
     .then(function (response) {
       if (response.status===200) {
         setIsCurrentPasswordValid(true);
       }
       else {
         setIsCurrentPasswordValid(false);
         console.log(response);
       }
     })
     .catch(function (error) {
       console.log(error);
     });
   }

   async function updateProfile(username, email) {

       await axios.put("/api/update_profile", {username: username, email: email}, {withCredentials:true, CacheControl: "Public"})
       .then(function (response) {
         if (response.status===200) {
           console.log(response);
         }
         else {
           console.log(response);
         }
       })
       .catch(function (error) {
         console.log(error.toJSON());
       });
     }

     async function updatePassword(currentPassword, newPasswordTwo) {

       await axios.put("/api/update_password", {currentPassword: currentPassword, newPassword: newPasswordTwo}, {withCredentials:true, CacheControl: "Public"})
       .then(function (response) {
         if (response.status===200) {
           console.log(response);
         }
         else {
           console.log(response);
         }
       })
       .catch(function (error) {
         console.log(error);
       });
     }

    async function handleSaveChanges(username, email, currentPassword, newPasswordTwo, isCurrentPasswordValid, isNewPasswordTwoValid) {
      if (isCurrentPasswordValid) {
        await updateProfile(username, email)
        if (isNewPasswordTwoValid) {
          await updatePassword(currentPassword, newPasswordTwo)
          setProfileChangesOK(true);
          setPasswordChangesOK(true);
        }
        else {
          setProfileChangesOK(true);
        }
      }
      else {
        setProfileChangesKO(true);
        setPasswordChangesKO(true);
      }
      setCurrentPassword("");
      setNewPasswordOne("");
      setNewPasswordTwo("");
      setEditMode(false);
    }

    return (
        <div>
        <ResponsiveAppBar opacity={0.95}/>
        { status !== 200 &&
          <div>
          <h2>Vous devez être connecté.e pour accéder à cette page.
          </h2>
          </div>
        }

        {status===200 &&
          <div>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container sx={{width:0.95}}>
          <Grid sx={{display:{xs:'none',md:'flex'}}} item md={2}>
            <SideMenu pages={ settingsLogged } pagenumber={pagenumber}/>
          </Grid>
          <Grid item md={9} xs={12}>
          { profileChangesOK && passwordChangesOK && <SuccessAlert message="Les informations de votre profil et votre mot de passe ont été changés avec succès." />}
          { profileChangesOK && !passwordChangesOK && !passwordChangesKO && <SuccessAlert message="Les informations de votre profil ont été changées avec succès." />}
          { profileChangesOK && passwordChangesKO &&
            <Stack spacing={2}>
            <ErrorAlert message="Le mot de passe n'a pas pu être modifié, veuillez réessayer." />
            <SuccessAlert message="Les informations de votre profil ont été changées avec succès." />
            </Stack>
          }
          { profileChangesKO && <ErrorAlert message="Une erreur est survenue, veuillez réessayer." />}
            <Paper sx={{textAlign:"justify",minHeight:400,mx:5,my:5,px:5,backgroundColor:'#FFFFFF',border:1,borderColor:'#000000',borderRadius:5}}>
            <ThemeProvider theme={theme}>
            <CardHeader
              avatar={
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <ManageAccountsOutlinedIcon />
                </Avatar>
              }
              title="Mon profil" titleTypographyProps={{variant:"h4",color:"secondary.main"}}
              subheader="Modifiez ici les informations de votre profil"
            />
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Nom d'utilisateur"
                      name="user"
                      value={username ?? ""} onChange={handleUsernameChange}
                      disabled={!editMode}
                      autoFocus
                    />
                    <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
                    Ce nom d'utilisateur sera affiché sur votre interface.
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Adresse e-mail"
                      name="email"
                      autoComplete="email"
                      value={email ?? ""} onChange={handleEmailChange}
                      disabled={!editMode}
                      autoFocus
                    />
                    <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
                    L'adresse e-mail ne servira qu'uniquement à valider le compte et réinitialiser le mot de passe si nécessaire.
                    </Typography>
                    { editMode &&
                      <div>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Mot de passe actuel"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={currentPassword ?? ""} onChange={handlePasswordChange}
                    />
                    <Typography variant="body2" color={isCurrentPasswordValid ? "green" : "red"} sx={{"text-align":"justify"}}>
                    {isCurrentPasswordValid ? "Le mot de passe actuel est correct." : "Le mot de passe indiqué est incorrect."}
                    </Typography>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password2"
                    label="Nouveau mot de passe"
                    type="password"
                    id="password2"
                    value={newPasswordOne ?? ""} onChange={handlePasswordOneChange}
                    autoComplete="current-password"
                    />
                    { newPasswordOne &&
                    <Typography variant="body2" color={isNewPasswordOneValid ? "green" : "red"} sx={{"text-align":"justify"}}>
                    {isNewPasswordOneValid ? "Le mot de passe remplit tous les critères." : "Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial."}
                    </Typography>
                  }
                  { !newPasswordOne &&
                    <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
                      Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial.
                    </Typography>}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password3"
                      label="Confirmez votre nouveau mot de passe"
                      type="password"
                      id="password3"
                      value={newPasswordTwo ?? ""}
                      onChange={handlePasswordTwoChange}
                    />
                    { newPasswordTwo &&
                    <Typography variant="body2" color={isNewPasswordTwoValid ? "green" : "red"} sx={{"text-align":"justify"}}>
                    {isNewPasswordTwoValid ? "Les deux mots de passe sont identiques." : "Les deux mots de passe doivent être identiques."}
                    </Typography>
                  }
                  { !newPasswordTwo &&
                    <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
                      Les deux mots de passe doivent être identiques.
                    </Typography>}
                    </div>}
                    { editMode &&
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={!isCurrentPasswordValid}
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSaveChanges(username,email,currentPassword,newPasswordTwo,isCurrentPasswordValid,isNewPasswordTwoValid)}
                    >
                      Sauvegarder les changements
                    </Button>}
                    { !editMode &&
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={openEditMode}
                    >
                      Modifier les informations de mon profil
                    </Button>}

                </Box>
            </ThemeProvider>
              </Paper>
              </Grid>
              </Grid>
              </Box>
              <Copyright/>
              </div>
            }
        </div>
    )
}

export default MyProfile
