import React from 'react';
import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import validateToken from './../requests/ValidateToken';
import axios from "axios";

export default function Graph() {

  const [wearings, setWearings] = React.useState([]);
  const [status, setStatus] = React.useState(0);

  React.useEffect(() => {

      const checkToken = async () => { 
        await validateToken({status, setStatus});
      }

      checkToken()
      // make sure to catch any error
      .catch(console.error);

      const fetchResults = async () => {

        let offset = new Date().getTimezoneOffset()/60;

        offset=0;

        await axios.get("https://api.hommes-engages.fr/api/wearings?collapsedResults=true&offset="+offset,{withCredentials:true})
        .then(function (response) {
          if (response.status===200) {
            setWearings(response.data);

          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      fetchResults()
      // make sure to catch any error
      .catch(console.error);

      // eslint-disable-next-line
     }, []);


  return (

    <ResponsiveContainer width="99%" height={400}>

{wearings && Array.isArray(wearings) && wearings.length > 0 ? (
    <ComposedChart width="80%" height={400} data={wearings}
margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
<defs>
<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
<stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
</linearGradient>
<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
<stop offset="1%" stopColor="#82ca9d" stopOpacity={0.4}/>
<stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
</linearGradient>
</defs>
<XAxis dataKey="selected_date" label={{ value: 'Date', position: 'insideBottomRight', offset: -20}}/>
<YAxis label={{ value: "Nombre d'heures de port", angle: -90, offset:20, position: "insideBottomLeft" }}/>
<Legend />
<CartesianGrid strokeDasharray="3 3" />
<Tooltip/>
<Area type="monotone" name="Temps de port" dataKey="diff" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)"/>
<Area type="monotone" name="Temps minimum requis" dataKey="minimum" stroke="#82ca9d" fillOpacity={1} strokeWidth={3} fill="url(#colorPv)" dot={false} />
</ComposedChart>) :
(<div> Veuillez renseigner des données pour accéder au graphique. </div>)}

  </ResponsiveContainer>


  );
}
