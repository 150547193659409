import React from 'react';
import './App.css';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import About from './pages/About';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GetInformed from './pages/GetInformed';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import MyProfile from './pages/MyProfile';
import MySettings from './pages/MySettings';
import MyContraception from './pages/MyContraception';
import ForgottenPassword from './pages/ForgottenPassword';
import Sengager from './pages/Sengager';
import Facts from './pages/Facts';
import './App.css';

import theme from './theme';

function App() {

  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>

    <Container disableGutters className="App">

    <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/sinformer/:id/" element={<GetInformed/>}/>
      <Route exact path="/lesaviezvous" element={<Facts/>}/>
      <Route exact path="/lesaviezvous/:index/" element={<Facts/>}/>
      <Route exact path="/sengager" element={<Sengager/>}/>
      <Route exact path="/connexion" element={<Login/>}/>
      <Route exact path="/inscription" element={<Register/>}/>
      <Route exact path="/apropos" element={<About/>}/>
      <Route exact path="/profil" element={<MyProfile/>}/>
      <Route exact path="/parametres" element={<MySettings/>}/>
      <Route exact path="/macontraception" element={<MyContraception/>}/>
      <Route exact path="/mdp_oublie" element={<ForgottenPassword/>}/>
      <Route exact path="/deconnexion" element={<Home/>}/>
      <Route path="/*" element={<NotFound/>}/>
    </Routes>
    </Container>
    </ThemeProvider>
    </BrowserRouter>
  );
} export default App;
