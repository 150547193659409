import React from 'react'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{my:2}}>
      {'Fait avec ❤️ pour '}
      <Link color="inherit" href="/">
        des hommes engagés
      </Link>{', '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
