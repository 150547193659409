import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import theme from './../theme';
import Copyright from './../components/Copyright';
import CreateUser from './../requests/CreateUser';
import { validate } from 'react-email-validator';
import { useNavigate } from "react-router-dom";
import LoginRequest from './../requests/LoginRequest';
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ResponsiveAppBar from './../components/ResponsiveAppBar';


export default function Register() {

  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(false);
  const [isUsernameValid, setIsUsernameValid] = React.useState(false);
  const [usernameExists, setUsernameExists] = React.useState(false);
  const [emailExists, setEmailExists] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  document.title = 'Hommes féministes | Inscription';

  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const [value, setValue] = React.useState({
    username: '',
    email: '',
    password: ''
  });

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
  };

  var passwordValidator = require('password-validator');
  var schema = new passwordValidator();
  schema
.is().min(8)                                    // Minimum length 8
.is().max(24)                                  // Maximum length 24
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits(1)                                // Must have at least 2 digits
.has().symbols(1)

  const handleUsernameChange = (event) => {
    setUsernameExists(false);
    handleChange(event);
    checkUsername(event.target.value);
    setIsUsernameValid(event.target.value.length > 2);
  }

  const handleEmailChange = (event) => {
    setEmailExists(false);
    handleChange(event);
    checkEmail(event.target.value);
    setIsEmailValid(validate(event.target.value));
  };

  const handlePasswordChange = (event) => { 
    handleChange(event);
    checkUsername(event.target.value);
    setIsPasswordValid(schema.validate(event.target.value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  async function checkEmail (email) {
    await axios.get(`https://api.hommes-engages.fr/api/emails/${email}`)
    .then(function (response) {
      if (response.status===200) {
        setEmailExists(true);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function checkUsername (username) {
    await axios.get(`https://api.hommes-engages.fr/api/users/${username}`)
    .then(function (response) {
      if (response.status===200) {
        setUsernameExists(true);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  async function handleRegister(username, email, password) {
    await CreateUser(username, email, password)
    await LoginRequest(email, password, false)
    navigate("/profil")
  }


  return (
    <ThemeProvider theme={theme}>
    <ResponsiveAppBar opacity={0.95}/>
      <Container className="Paper-Container" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inscription
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} minWidth={{md:"400px"}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nom d'utilisateur"
              name="username"
              value={value.username} onChange={handleUsernameChange}
              autoFocus
            />
            { value.username.length > 0 && !usernameExists &&
            <Typography variant="body2" color={isUsernameValid ? "green" : "red"} sx={{"text-align":"justify"}}>
            {isUsernameValid ? "Le nom d'utilisateur renseigné est valide." : "Le nom d'utilisateur doit contenir au minimum 3 caractères."}
            </Typography>
          }
          {value.username.length === 0 &&
            <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
              Ce nom d'utilisateur sera affiché sur votre interface.
            </Typography>}
          {usernameExists &&
            <Typography variant="body2" color="red" sx={{"text-align":"justify"}}>
              Le nom d'utilisateur existe déjà.
            </Typography>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoComplete="email"
              value={value.email} onChange={handleEmailChange}
              autoFocus
            />
            { value.email.length > 0 && !emailExists &&
            <Typography variant="body2" color={isEmailValid ? "green" : "red"} sx={{"text-align":"justify"}}>
            {isEmailValid ? "L'adresse email renseignée est valide." : "Veuillez renseigner une adresse email valide."}
            </Typography>
          }
          {value.email.length === 0 &&
            <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
              L'adresse e-mail ne servira qu'uniquement à valider le compte et réinitialiser le mot de passe si nécessaire.
            </Typography>}
          {emailExists &&
            <Typography variant="body2" color="red" sx={{"text-align":"justify"}}>
              L'adresse email existe déjà.
            </Typography>}


            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              id="password"
              value={value.password} onChange={handlePasswordChange}
              autoComplete="current-password"

              variant="outlined"
              type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />


            { value.password.length > 0 &&
            <Typography variant="body2" color={isPasswordValid ? "green" : "red"} sx={{"text-align":"justify"}}>
            {isPasswordValid ? "Le mot de passe remplit tous les critères." : "Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial."}
            </Typography>
          }
          {value.password.length === 0 &&
            <Typography variant="body2" color="text.secondary" sx={{"text-align":"justify"}}>
              Le mot de passe doit être entre 8 et 24 caractères, et contenir au minimum une lettre minuscule, une majuscule et un caractère spécial.
            </Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              id="registerButton"
              disabled={!(isUsernameValid && isEmailValid && isPasswordValid && !usernameExists && !emailExists)}
              sx={{ mt: 3, mb:1 }}
              onClick={() => handleRegister(`${value.username}`,`${value.email}`,`${value.password}`)}
            >
              Inscription
            </Button>
            {!(isUsernameValid && isEmailValid && isPasswordValid && !usernameExists && !emailExists) &&
              <Typography variant="body2" color="red" sx={{"text-align":"center",mb:2}}>
                Tous les champs doivent être complétés correctement.
              </Typography>}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
